@import 'styles/helpers/helpers';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;
  overflow: auto;
  padding: 15px 0;
  animation: show 0.3s ease;

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
  }

  &__bg {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: rgba(15, 15, 15, 0.7);
  }

  &__content {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    background: url('/images/modal-pattern-1.svg') no-repeat 0 0,
      url('/images/modal-pattern-2.svg') no-repeat right bottom,
      $theme_background_color;
    color: $main_text_color;
    width: 320px;
    max-width: 100%;
    border-radius: 7px;
    padding: 52px 46px 36px;
  }

  &__closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $green_theme_color;
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__closeImg {
    margin-left: 5px;
  }

  &__title {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
  }

  &__button {
    margin: 24px auto 0;
    max-width: 173px;
  }
}

@media ($_mobile_down) {
  .modal {
    &__content {
      background-image: url('/images/modal-pattern-mobile-1.svg'),
        url('/images/modal-pattern-mobile-2.svg');
    }
  }
}

@media ($_tablet_up) {
  .modal {
    &__title {
      font-size: 34px;
      line-height: 42px;
    }

    &__content {
      padding: 77px 168px 64px;
      width: 840px;
    }

    &__button {
      margin-top: 100px;
      max-width: 340px;
    }

    &__closeButton {
      font-size: 20px;
      right: 30px;
      top: 26px;
    }

    &__closeImg {
      margin-left: 10px;
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
}
