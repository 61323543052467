@import 'styles/helpers/helpers';

.button {
  padding: 16px;
  background: $green_theme_color;
  @include globalSemiBoldFont();
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $main_text_color;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
  box-sizing: border-box;
  outline: none !important;
  box-shadow: none;

  &[disabled] {
    cursor: default;
    background: $black_theme_color;
    color: $black_theme_color;
  }

  &__min {
    padding: 8px 40px;
  }

  &__w100 {
    width: 100%;
  }
}

@media ($_laptop_up) {
  .button {
    &:hover {
      background: $main_text_color;
      color: $black_theme_color;
    }
  }
}
