@import 'styles/helpers/helpers';

.feedback {
  padding-top: 72px;
  margin-top: -70px;

  &__wrapper {
    background: $background_form_black;
    padding: 30px 0;
  }

  &__demo {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }

  &__info {
    margin-top: 30px;
  }

  &__form {
    margin-top: 30px;
    flex-shrink: 0;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 20px;
    color: $additional_text_color;
    margin-top: 20px;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__fieldInput {
    padding: 14px 24px;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 3px;
    background-color: transparent;
    @include globalRegularFont();
    font-size: 18px;
    line-height: 24px;
    color: $main_text_color;
    outline: none;
    width: 100%;

    &::placeholder {
      color: $primary_gray_color;
    }

    &.error {
      color: $red-error-color;
      border-color: $red-error-color;

      &::placeholder {
        color: $red-error-color;
      }
    }
  }

  &__errorLabel {
    color: $red-error-color;
    margin: 7px 0 -7px;
  }

  &__privacyCheckbox {
    display: none;

    &:checked + label::before {
      background-image: url(/images/icons/feedback-check.svg);
      background-position: center;
      padding: 0;
      border-color: $green_theme_color;
    }
  }

  &__privacyLabel {
    display: flex;
    cursor: pointer;
    user-select: none;
    color: $main_text_color;
    margin: 22px 0 0 3px;
    font-size: 16px;
    line-height: 24px;

    &::before {
      content: '';
      width: 18px;
      height: 18px;
      border: 2px solid $main_text_color;
      margin: 2px 12px 0 0;
      cursor: pointer;
      flex: 0 0 auto;
      box-sizing: border-box;
    }

    &.error {
      &::before {
        border-color: $red-error-color;
      }
    }
  }

  &__submit {
    text-align: right;
    margin-top: 30px;
  }
}

@media ($_tablet_up) {
  .feedback {
    padding-top: 85px;

    &__wrapper {
      padding: 67px 0;
    }

    &__container {
      padding: 0 50px;
    }

    &__demo {
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -15px;

      & > * {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    &__title {
      font-size: 32px;
    }

    &__info {
      max-width: 400px;
      margin-bottom: 65px;
    }
  }
}

@media ($_laptop_up) {
  .feedback {
    &__subtitle {
      font-size: 18px;
      line-height: 24px;
    }

    &__form {
      width: 400px;
    }

    &__fieldInput {
      padding: 22px 24px;
    }

    &__field {
      margin-bottom: 29px;
    }
  }
}

@media ($_desktop_up) {
  .feedback {
    &__form {
      width: 500px;
    }
  }
}
