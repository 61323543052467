@import 'styles/helpers/helpers';

.selectBox {
  position: relative;
  margin-top: 35px;
}

.selectedOption {
  color: $main_text_color;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.arrow {
  display: none;
  margin-left: 10px;
  padding-bottom: 5px;
}

.optionsGrid {
  display: block;
}

.optionsList {
  display: flex;
  gap: 4px;
}

.option {
  color: $main_text_color;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 16px;
  padding: 3px 9px;
  border-radius: 7px;
  text-transform: uppercase;

  &.active {
    color: $green_theme_color;
    background: $black_theme_color;
  }
}

.desktopVersion {
  &.selectBox {
    margin-top: 0;
  }

  &.selectBoxOpen {
    .arrow {
      transform: scaleY(-1);
      padding-bottom: 7px;
    }

    .optionsGrid {
      transform: none;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .optionsGrid {
    position: absolute;
    margin: 3px 0 0;
    background: $main_text_color;
    min-width: 152px;
    left: 0;
    top: 100%;
    transform: translateY(10px);
    transition: all 0.3s;
    opacity: 0;
    pointer-events: none;
    z-index: 2;
  }

  .optionsList {
    display: block;
    padding: 8px 0;
  }

  .option {
    padding: 11px 16px;
    font-size: 14px;
    color: $black_theme_color;
    transition: background-color 0.3s;
    text-transform: none;

    &:hover {
      background-color: $light_green;
    }

    &.active {
      background: $light_green url('/images/icons/check.svg') no-repeat right
        15px center;
      color: $black_theme_color;
      font-size: 16px;
      font-weight: 500;
      padding-right: 40px;
    }
  }
}

@media ($_tablet_up) {
  .desktopVersion {
    .arrow {
      display: block;
    }

    .optionsGrid {
      right: 0;
      left: auto;
    }
  }
}
