@mixin globalFont {
  font-family: Jost;
  font-style: normal;
}

@mixin globalRegularFont() {
  @include globalFont();
  font-weight: 400;
}

@mixin globalMediumFont() {
  @include globalFont();
  font-weight: 500;
}

@mixin globalSemiBoldFont() {
  @include globalFont();
  font-weight: 600;
}

@mixin globalBoldFont() {
  @include globalFont();
  font-weight: 700;
}

@mixin globalBlackFont() {
  @include globalFont();
  font-weight: 800;
}
