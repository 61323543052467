@import 'styles/helpers/helpers';

.advantages {
  padding-top: 120px;
  margin-top: -35px;

  &__text {
    font-size: 16px;
    line-height: 20px;
    color: $additional_text_color;
    margin-top: 10px;
  }

  &__image {
    margin-top: 41px;
    text-align: center;
  }

  &__item {
    margin-bottom: 43px;
  }

  &__item1 {
    &__image {
      margin-top: 20px;
    }
  }

  &__item2 {
    img {
      max-width: 68%;
      position: relative;
      right: 8%;
    }
  }

  &__item3 {
    img {
      max-width: 51.5%;
    }
  }
}

@media ($_tablet_up) {
  .advantages {
    margin-top: -127px;
    position: relative;

    &::before {
      content: '';
      background: url('/images/bg-round-2.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      bottom: -350px;
      width: 1210px;
      height: 1517px;
      z-index: -1;
    }

    &__itemReverse {
      flex-direction: row-reverse;
      justify-content: center;
    }

    &__info {
      max-width: 46.5%;
      flex-shrink: 0;
      margin-bottom: 42px;
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;
    }

    &__image {
      max-width: 50%;

      img {
        max-width: 100%;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__item2 {
      .advantages {
        &__image {
          margin-top: 0;
          flex-grow: 1;
          position: relative;
          bottom: 5px;
        }

        &__info {
          margin: 0 16px 8px;
        }
      }

      img {
        right: 0;
      }
    }

    &__item3 {
      .advantages {
        &__info {
          margin-bottom: 0;
        }

        &__image {
          position: relative;
          bottom: 54px;
        }
      }
    }
  }
}

@media ($_desktop_up) {
  .advantages {
    &__item2 {
      .advantages {
        &__image {
          left: 17px;
        }

        &__info {
          margin: 0 16px 8px 0;
        }
      }
    }

    &__image {
      max-width: none;
    }
  }
}

@media (min-width: 1600px) {
  .advantages {
    &::before {
      transform: scaleX(1.5);
    }
  }
}
