$_tablet_up: 'min-width: 768px';
$_laptop_up: 'min-width: 992px';
$_desktop_up: 'min-width: 1240px';
$_mobile_down: 'max-width: 767px';
$_tablet_down: 'max-width: 991px';
$_desktop_down: 'max-width: 1239px';
$_hd_up: 'min-width: 2000px';

$theme_background_color: #050505;
$main_text_color: #ffffff;
$additional_text_color: #aeaeb2;
$green_theme_color: #34c759;
$black_theme_color: #050505;
$dark_gray_color: #2a2a2a;
$primary_gray_color: #565656;
$light_green: #ebfaef;
$red-error-color: #e33f45;
$background_form_black: #0f0f0f;
