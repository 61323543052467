@import 'styles/helpers/helpers';

.footer {
  background: $background_form_black;
  padding: 10px 0 61px;

  &__list {
    padding: 15px 0 22px;
  }

  &__listLine {
    border-top: 1px solid $dark_gray_color;
  }

  &__logo {
    max-width: 130px;
  }

  &__menuList {
    margin-top: 16px;
  }

  &__whiteLink {
    color: $main_text_color;

    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: $main_text_color;
    }
  }

  &__greenLink {
    color: $green_theme_color;

    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: $green_theme_color;
    }
  }

  &__menuLink {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.01em;
    transition: color 0.3s;
    margin: 7px 0;
    display: inline-block;
  }

  &__contacts {
    margin-top: 16px;
  }

  &__mail {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  &__whatsapp {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;

    svg {
      margin-right: 8px;
      fill: $main_text_color;
      transition: all 0.3s;
      width: 24px;
      height: 24px;
    }
  }

  &__copyright {
    color: $additional_text_color;
  }

  &__privacy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }

  &__privacyLink {
    color: $additional_text_color !important;
    opacity: 0.5;
    text-decoration: underline;
    display: inline-block;
    margin: 7px 0;
  }

  &__itemCompany {
    margin-top: 16px;
  }

  &__friflexLogo {
    max-width: 120px;
  }

  &__developedBy {
    margin-top: 20px;
  }
}

@media ($_tablet_up) {
  .footer {
    padding: 15px 0;

    &__list {
      display: flex;
      padding: 25px 0 14px;
    }

    &__item {
      margin-top: 0;

      &:first-child {
        width: 30%;
      }

      &:nth-child(2) {
        width: 40%;
      }
    }

    &__logo {
      max-width: 180px;
    }

    &__menuList {
      margin-top: 0;
    }

    &__menuLink {
      margin: 0 0 30px;
    }

    &__whiteLink {
      transition: color 0.3s;

      &:hover {
        color: $green_theme_color;
      }
    }

    &__whatsapp {
      margin-top: 15px;

      &:hover {
        svg {
          fill: $green_theme_color;
        }
      }

      svg {
        transition: all 0.3s;
      }
    }

    &__copyright {
      margin-top: 10px;
    }

    &__privacyLink {
      margin: 0 0 12px;
    }

    &__friflexLogo {
      max-width: none;
    }

    &__developedBy {
      margin-top: 15px;
    }
  }
}

@media ($_laptop_up) {
  .footer {
    &__item {
      &:first-child {
        width: 34.2%;
      }

      &:nth-child(2) {
        width: 34%;
      }
    }

    &__logo {
      max-width: none;
    }
  }
}

@media ($_desktop_up) {
  .footer {
    &__item {
      &:first-child {
        width: 34.2%;
      }

      &:nth-child(2) {
        width: 31.5%;
      }
    }
  }
}
