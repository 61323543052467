@import 'styles/helpers/helpers';

.header {
  display: flex;
  padding: 30px 0;
  background-color: $theme_background_color;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 15;

  &.static {
    position: relative;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    display: none;
  }

  &__logo {
    width: 130px;
  }

  &__mobileWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__mobileBg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $black_theme_color;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &__mobileBgVisible {
    opacity: 0.7;
  }

  &__burger {
    display: block;
    width: 64px;
    height: 38px;
    position: relative;
    transition: all 0.3s;

    span,
    &::before,
    &::after {
      width: 18px;
      height: 2px;
      background-color: $main_text_color;
      position: absolute;
      left: 50%;
      border-radius: 1px;
      transition: all 0.3s;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      transform: translateX(-50%);
    }

    span {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &::before {
      top: calc(50% - 6px);
    }

    &::after {
      top: calc(50% + 4px);
    }
  }

  &__mobileMenu {
    position: fixed;
    width: calc(100% - 40px);
    top: 50px;
    left: 50%;
    transform: translate(-50%, 30px);
    background: $dark_gray_color;
    border-radius: 7px;
    padding: 52px 20px 20px;
    transition: all 0.3s;
    opacity: 0;
  }

  &__mobileMenuVisible {
    transform: translateX(-50%);
    opacity: 1;
  }

  &__close {
    position: absolute;
    right: 12px;
    top: 5px;
    width: 40px;
    height: 40px;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__menuItem {
    margin: 16px 0;
  }

  &__menuLink {
    color: $main_text_color;
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.01em;
    transition: color 0.3s;
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: $main_text_color;
    }
  }

  &__whatsapp {
    margin-top: 30px;
    padding-right: 20px;
    display: inline-block;
  }
}

@media ($_laptop_up) {
  .header {
    padding: 36px 0;

    &__burger,
    &__mobileMenu {
      display: none;
    }

    &__list {
      display: flex;
      align-items: center;
      margin: 0 -20px;
      position: relative;
      top: 2px;

      & > * {
        margin: 0 20px;
      }
    }

    &__logo {
      width: auto;
    }

    &__menuList {
      display: flex;
      margin: 0 -15px;
    }

    &__menuItem {
      margin: 0 20px;
    }

    &__menuLink {
      font-size: 16px;

      &:hover {
        color: $green_theme_color;
      }
    }
  }
}

@media ($_desktop_up) {
  .header {
    &__menuLink {
      font-size: 18px;
    }
  }
}
