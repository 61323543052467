@import 'styles/helpers/helpers';

.main {
  min-height: calc(100vh - 100px);

  &__wrapper {
    position: relative;
  }

  &__image {
    position: absolute;
    width: 210px;
    left: -130px;
    top: 20px;
  }

  &__phone {
    position: absolute;
    left: 50%;
    top: -12px;
    transform: translateX(-45%);
    width: 147px;
  }

  &__video {
    display: none;
    position: absolute;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    background: rebeccapurple;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
  }

  &__info {
    padding: 42px 0 40px;
  }

  &__titles {
    padding-left: 92px;
    min-height: 306px;
    height: 50vh;
  }

  &__subTitle {
    padding-top: 10px;
    color: $additional_text_color;
    font-size: 14px;
    line-height: 20px;
  }

  &__arrow {
    text-align: center;

    svg {
      width: 22px;
      transition: all 0.3s;
      animation: arrow-down ease-in 3s infinite;
    }
  }
}

@media (min-width: 400px) {
  .main {
    min-height: 0;

    &__image {
      left: -50px;
    }

    &__titles {
      padding-left: 172px;
      height: auto;
    }
  }
}

@media ($_tablet_up) {
  .main {
    position: relative;

    &::before {
      content: '';
      background: url('/images/bg-round-1.svg') no-repeat center;
      background-size: contain;
      position: absolute;
      right: -200px;
      top: 0;
      width: 652px;
      height: 1517px;
      z-index: -1;
    }

    &__wrapper {
      padding-bottom: 40px;
    }

    &__image {
      left: 0;
      width: 380px;
      top: 50%;
      transform: translateY(-79.5%);
    }

    &__phone {
      width: 210px;
      transform: translateX(-50%);
      top: 45px;
    }

    &__video {
      border-radius: 15px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }

    &__videoFull {
      position: absolute;
      left: -5px;
      width: 380px;
      top: 50%;
      transform: translateY(-61%);

      video {
        width: 100%;
        max-height: 100%;
      }
    }

    &__videoPlayButton {
      background: url('/images/icons/video-play.svg') no-repeat center;
      background-size: contain;
      width: 90px;
      height: 90px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    &__info {
      padding: 0 0 0 15px;
      width: 45%;
      margin-left: auto;
      min-height: 480px;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__titles {
      padding-left: 0;
      min-height: 0;
      height: auto;
    }

    &__subTitle {
      font-size: 18px;
      padding-top: 20px;
    }

    &__button {
      margin-top: 62px;

      button {
        width: auto;
      }
    }

    &__arrow {
      margin: 0;

      svg {
        width: 37px;
      }
    }
  }
}

@media ($_tablet_up) and (max-height: 1100px) {
  .main {
    &__wrapper {
      min-height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      padding-bottom: 8vh;
    }
  }
}

@media ($_laptop_up) {
  .main {
    &::before {
      right: -100px;
    }

    &__image {
      left: 20px;
      width: 478px;
    }

    &__phone {
      width: 264px;
      top: 63px;
    }

    &__video {
      border-radius: 20px;
      width: calc(100% - 24px);
      height: calc(100% - 24px);
    }

    &__videoFull {
      width: 480px;
    }

    &__info {
      min-height: 480px;
      margin-bottom: 100px;
    }
  }
}

@media ($_desktop_up) {
  .main {
    &::before {
      right: 0;
    }

    &__videoFull {
      width: 530px;
    }
  }
}

@media ($_laptop_up) and (max-height: 1100px) {
  .main {
    &__info {
      margin-bottom: 9vh;
    }
  }
}

@media ($_laptop_up) and (max-height: 730px) {
  .main {
    &__image {
      top: -30px;
      transform: none;
      width: 400px;
    }

    &__phone {
      width: 221px;
      top: 55px;
    }

    &__info {
      min-height: 370px;
      height: 60vh;
      margin-bottom: 4.8vh;
    }

    &__videoFull {
      min-height: 450px;
      height: 70vh;
      top: 0;
      transform: none;
    }
  }
}

@media (min-width: 1600px) {
  .main {
    &::before {
      transform: scaleX(2);
    }
  }
}

@keyframes arrow-down {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(10px);
  }

  40% {
    transform: translateY(0);
  }
}
